<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <DatePicker
          v-model="dateRange"
          type="datetimerange"
          placement="bottom-end"
          placeholder="日期范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
    </Form>
    <Table
      stripe
      :columns="tableColumns"
      :data="itemList"
      :loading="isLoading"
    ></Table>
    <Pager :total="itemListCount" :current.sync="page" />
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"

export default {
  name: "AnalysisPlanPage",
  components: { ContentWrapper, Pager },
  data() {
    return {
      topLinks: [{ title: "地理位置分析", link: this.$route.path }],
      dateRange: ["", ""],
      itemList: [
        {
          name: "Auckland / 奥克兰",
          date: "2020-09-10",
          pv: 0,
          uv: 0,
          activateUser: 0,
          published: 0,
          itemViewed: 0,
        },
        {
          name: "新西兰以外",
          date: "2020-09-10",
          pv: 10,
          uv: 1,
          activateUser: 10,
          published: 0,
          itemViewed: 0,
        },
      ],
      itemListCount: 0,
      page: 1,
      tableColumns: [
        { title: "位置", key: "name" },
        { title: "日期", key: "date" },
        { title: "PV", key: "pv" },
        { title: "UV", key: "uv" },
        { title: "活跃用户", key: "activateUser" },
        { title: "发布商品数", key: "published" },
        { title: "浏览商品数", key: "itemViewed" },
      ],
      isLoading: false,
    }
  },
  computed: {},
  watch: {},
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {},
  },
}
</script>
